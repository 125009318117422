<template>
  <Breadcrumb />
  <Grid
    :settings="gridSettings"
    :columns="gridColumns"
    @onEditButtonClick="onEditButtonClick"
  />
</template>
<script>
export default {
  name: "DesignKanbanList",
  data() {
    return {
      gridSettings: {
        action: "DesignKanbanList",
        requestUrl: String.format("/Lcdp-KanbanViewList"),
        requestUrlRouteParamNames: [],
        routeRequiredParameters: [],
        isGatewayRequest: true,

        allowSearchPanel: false,
        allowExcelExport: false,
        allowDragAndDrop: false,
        allowPaging: true,
        allowDeleting: false,
        autoOrderProcess: true,

        buttons: [
          {
            name: "edit",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: false,
            emitMethodName: "onEditButtonClick",
          },
        ],
      },
      gridColumns: [
        {
          text: this.$t(
            "KanbanView.CustomObjectName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "customObjectName",
          type: "string",
          visible: true,
          width: 100,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "KanbanView.Name",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "name",
          type: "string",
          visible: true,
          width: 100,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "KanbanView.KanbanColumnField",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "kanbanColumnFieldName",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "KanbanView.HeaderField",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "headerFieldName",
          type: "string",
          visible: true,
          width: 150,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "KanbanView.Description",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "descriptionFieldName",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
      ],
    };
  },
  methods: {
    onEditButtonClick(rowData) {
      let route = this.$router.resolve({
        name: "CustomObjectEditKanban",
        params: {
          customObjectId: rowData.customObjectPublicId,
          kanbanId: rowData.publicId,
        },
      });
      window.open(route.href);
    },
  },
  mounted() {},
};
</script>
